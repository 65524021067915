import "./index.css"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import firebase from "firebase/app"

// initialize firebase
var config: object = {
  apiKey: "AIzaSyCEgxnTTQpshZsSN6YRr1NREfyvatpgW1E",
  authDomain: "montgomery-290895.firebaseapp.com",
  databaseURL: "https://montgomery-290895.firebaseio.com",
  projectId: "montgomery-290895",
  storageBucket: "montgomery-290895.appspot.com",
  messagingSenderId: "117238195938",
  appId: "1:117238195938:web:07e7cff0f83583cb6120bb",
} // TODO: move into private folder

firebase.initializeApp(config)

let SERVERERR: { error: any; timestamp?: Date } = { error: false }

export const setServerError = (errData: any) => {
  SERVERERR = {
    error: errData,
    timestamp: errData ? new Date() : undefined,
  }
  renderApp()
}

const renderApp = () => {
  ReactDOM.render(<App serverError={SERVERERR} />, document.getElementById("root"))
}

renderApp()
