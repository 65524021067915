import firebase from "firebase/app"
import "firebase/auth"
import { saveAs } from "file-saver"
import axios, { AxiosResponse } from "axios"
import { setServerError } from "../index"

let DEBUGMODE: boolean = true

export const setDebugMode = (bool: boolean) => {
  DEBUGMODE = bool
}

export const getDebugMode = () => {
  return DEBUGMODE
}

const apiEndpoint = (endpoint: string) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return "http://localhost:3001/" + endpoint
  } else {
    return "https://montgomery-290895.appspot.com/" + endpoint
  }
}

type axiosType = {
  url: string
  headers?: {}
  [index: string]: any
}

export const fireAxios = (axiosData: axiosType, callback: (response: AxiosResponse) => void) => {
  DEBUGMODE && console.log(axiosData)
  firebaseAuth((idToken: string) => {
    axios({
      ...axiosData,
      url: apiEndpoint(axiosData.url),
      headers: { "FIREBASE-ID-TOKEN": idToken, ...axiosData.headers },
    })
      .then((response) => {
        setServerError(false)
        DEBUGMODE && console.log(response)
        callback(response)
      })
      .catch((error) => {
        setServerError(error)
        DEBUGMODE && console.log(error)
        // callback(error)
        // TODO: callback on error. Requires custom error handling everywhere fireAxios is called
      })
  })
}

export const round2 = (num: string | number | undefined) => {
  return parseFloat(parseFloat(num === undefined || num === 0 || num === "" ? "0" : num.toString()).toFixed(2))
}

export const toCurrency = (value: string | number | undefined, noDollarSign?: boolean) => {
  let result = parseFloat(parseFloat((value || "0").toString()).toFixed(2))
  if (!isNaN(result)) {
    return (noDollarSign ? "" : "$ ") + result.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } else {
    return (noDollarSign ? "" : "$ ") + "NaN"
  }
}

export const calculateMargin = (sell: string | number, cost: string | number, discount?: string | number) => {
  let sellingNumber: number = parseFloat(sell.toString())
  let costNumber: number = parseFloat(cost.toString())
  let discountNum: number = parseFloat((discount || "").toString())

  let discountedSell: number = sellingNumber * (discountNum ? 1 - discountNum / 100 : 1)
  return sellingNumber === 0 ? 0 : ((discountedSell - costNumber) / discountedSell) * 100
}

export const calculateSell = (margin: number, cost: number) => {
  let marginNumber: number = margin
  let costNumber = cost

  return -(100 * costNumber) / (marginNumber - 100)
}

export const toReadableDate = (date: Date | string | undefined) => {
  if (!date) return null
  let d = new Date(date)
  return d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate() + " " + d.toLocaleTimeString()
}

export const toFileDate = (date: Date | string) => {
  if (!date) return null
  let d = new Date(date)
  return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate()
}

export const inputDateToDateObj = (dateString: string | undefined) => {
  if (!dateString) return undefined
  const dateArr = dateString.split("-")
  const [year, month, day] = dateArr
  return new Date(`${month}-${day}-${year}`)
}

export const dateObjToInputDate = (dateObj: Date) => {
  if (!dateObj) return null
  return dateObj.toJSON().slice(0, 10)
}

const firebaseAuth = (callback: any) => {
  if (firebase.auth().currentUser) {
    ;(firebase.auth().currentUser as firebase.User)
      .getIdToken()
      .then((idToken) => {
        callback(idToken)
      })
      .catch((error) => console.log(error))
  } else {
    console.log("No User")
  }
}

const binaryToOctet = (s: string) => {
  var buf = new ArrayBuffer(s.length)
  var view = new Uint8Array(buf)
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

export const openNewTab = (link: string, id: number) => {
  localStorage.setItem("newTabState", JSON.stringify({ id: id, link: link }))
  window.open(window.location.origin, "_blank")
}

export const download = (type: "PDF" | "Excel", fileData: any, title: string) => {
  if (!fileData) {
    return
  }
  switch (type) {
    case "Excel":
      saveAs(new Blob([binaryToOctet(fileData)], { type: "application/octet-stream" }), title + ".xlsx")
      break
    case "PDF":
      saveAs(new Blob([fileData], { type: "application/pdf" }), title + ".pdf")
      break
    default:
      console.log("How'd you get here?")
  }
}

export type restrictions =
  | "province"
  | "currency-code"
  | "country-code"
  | "product-line"
  | "customer-num"
  | "unsigned-int"
  | "non-zero-unsigned-int"
  | "unsigned-float"
  | "phone-number"
  | "email"
  | "currency"
  | undefined

export const regexStandards = (title: restrictions) => {
  switch (title) {
    case "province":
      return /^[A-Z]{2}$/
    case "currency-code":
      return /^[A-Z]{2}$/
    case "country-code":
      return /^[A-Z]{3}$/
    case "product-line":
      return /^[0-9A-Z]{3}$/
    case "customer-num":
      return /^[0-9A-Z]{6}$/
    case "unsigned-int":
      return /^\d+$/
    case "non-zero-unsigned-int":
      return /^0*[1-9]\d*$/
    case "unsigned-float":
      return /^(?:[1-9]\d*|0)?(?:\.\d+)?$/
    case "phone-number":
      return /^\d\d\d-\d\d\d-\d\d\d\d(\x\d+)?$/
    case "email":
      return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    case "currency":
      return /^\$?\s?(\d)+(\.\d+)?$/
    default:
      return /[\s\S]*/
  }
}

export const parseUnderscores = (name: string) => {
  let nameArr = name.split("_")
  nameArr = nameArr.map((x) => x.charAt(0).toUpperCase() + x.substring(1))
  return nameArr.join(" ")
}
